//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainChartExample from './base/charts/MainChartExample'
import WidgetsDropdown from './base/widgets/WidgetsDropdown'
import moment from "moment/moment";
import {Message} from "element-ui";
import {fetchDashboardData} from "@/api/admin";

export default {
  name: 'Dashboard',
  components: {
    MainChartExample,
    WidgetsDropdown
  },
  data () {
    return {
      currentMonth: moment().format('MMMM YYYY'),
      summary: {
        contract_detail: []
      },
    }
  },
	async created() {
		document.title = this.$route.name + " : ONE Tracemoney"
    this.$session.start()
    this.fullname = this.$session.get('fullname')
  },
  async mounted() {
    await this.getDashboardData()
  },
  computed: {
    summaryData() {
      return this.summary
    },
    contractDetail() {
      return this.summary.contract_detail
    },
  },
  methods: {
    getDashboardData() {
      fetchDashboardData().then(response => {
        const check = response.header
        const data = response.body
        if (check.error === 'N') {
          this.summary = JSON.parse(JSON.stringify(data))
        }
      }).catch(error => {
        Message({
          message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          type: 'error',
          duration: 5 * 1000,
        })
        this.errorMsg = error
        console.log(error)
      })
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
